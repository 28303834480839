import {Inject, Injectable, PLATFORM_ID} from '@angular/core'
import {TranslocoService} from '@ngneat/transloco'
import {NavigationEnd, Router} from '@angular/router'
import {Language} from './language'
import {ApplicationRoute} from '../../craft/utils/application.route'
import {PUBLIC_ROUTES} from '../../shared/routing/public.routes'
import {filter} from 'rxjs/operators'
import {getPathFromUrl} from '../utils/url'
import {DOCUMENT, isPlatformBrowser} from '@angular/common'
import {REDIRECT_TO_PARAMETER, SIGN_OUT_PARAMETER} from '../../pages/public/auth/sign-in/sign-in.types'
import {PathOptions, PathService} from '../../craft/utils/path.service'

/*
  RouterLink directive is bugged when working with relative links.
  This service helps by generating full paths based on the current language of the app.
  Reference:
    https://github.com/angular/angular/issues/13011
    https://github.com/angular/angular/pull/37446
  This should be fixed in Angular 10
 */
@Injectable({
  providedIn: 'root',
})
export class LinkService {
  /** History of routes that have been fully activated (based on lifecycle NavigationEnd) */
  private readonly routeHistory: string[] = []
  private _referrer?: string

  constructor(
    @Inject(TranslocoService) private localeService: TranslocoService,
    @Inject(DOCUMENT) private document: Document,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: Record<string, unknown>,
    private readonly pathService: PathService,
  ) {
    this.observeRouteHistory()
  }

  get referrer(): string | undefined {
    return this._referrer
  }

  get previousPage(): string | undefined {
    // get the previous page
    return this.routeHistory.slice(-2, -1).pop()
  }

  get homePath() {
    return `/${this.localeService.getActiveLang()}`
  }

  get searchPath() {
    return this.localizedPathTo(PUBLIC_ROUTES.SEARCH)
  }

  get happinessGuaranteePagePath() {
    return this.localizedPathTo(PUBLIC_ROUTES.ARTICLE_PAGE, {
      routeParams: {pageSlug: 'happinessguarantee'},
    })
  }

  localizedPathToNotFound(notFoundUrl?: string): string {
    return this.localizedPathTo(
      PUBLIC_ROUTES.NOT_FOUND,
      notFoundUrl ? {queryParams: {path: encodeURI(notFoundUrl)}} : undefined,
    )
  }

  localizedPathToUnknownError(): string {
    return this.localizedPathTo(PUBLIC_ROUTES.ERROR)
  }

  /**
   * Localized path to a previous page within the app
   * @note falls back to the home page if there aren't any previous page
   * @note this is a path, and not a URL, meaning the any query parameters or fragment will be dropped !
   */
  localizedPathToPreviousPage(): string {
    const previousNav = this.router.getCurrentNavigation()?.previousNavigation
    if (previousNav) {
      // previous location is within our app
      return getPathFromUrl(this.router.serializeUrl(previousNav.finalUrl ?? previousNav.extractedUrl))
    } else {
      return this.homePath
    }
  }

  localizedPathTo(destination: ApplicationRoute, opts?: PathOptions, language?: Language): string {
    return this.pathService.localizedPathTo(destination, opts, language)
  }

  localizedPathToCurrentLocation(newLang: Language): string {
    if (this.router.url.length < 4) {
      return `/${newLang}`
    }

    return `/${newLang}/${this.router.url.slice(4)}`
  }

  navigateTo(destination: ApplicationRoute, opts?: NavigationOptions): Promise<boolean> {
    return this.router.navigateByUrl(
      this.localizedPathTo(destination, {
        ...opts,
        // fragments are supported with angular router, leave them if any
        stripFragments: false,
      }),
    )
  }

  navigateToSignIn(opts?: {signOut?: boolean; redirectTo?: string}) {
    const queryParams: Record<string, string> = {}
    let route = PUBLIC_ROUTES.SIGN_IN
    if (opts?.signOut) {
      queryParams[SIGN_OUT_PARAMETER] = 'true'
    }
    if (opts?.redirectTo) {
      route = PUBLIC_ROUTES.SIGN_IN_RETURNING
      queryParams[REDIRECT_TO_PARAMETER] = opts.redirectTo
    }

    return this.navigateTo(route, {queryParams})
  }

  saveReferrer() {
    if (isPlatformBrowser(this.platformId)) {
      this._referrer = this.document.referrer
    }
  }

  private observeRouteHistory(): void {
    this.router.events?.pipe(filter(e => e instanceof NavigationEnd)).subscribe((e: NavigationEnd) => {
      // remove from route history when navigating back
      const index = this.routeHistory.indexOf(e.urlAfterRedirects)
      if (index > -1 && index === this.routeHistory.length - 2) {
        this.routeHistory.splice(index, this.routeHistory.length - index)
      }

      // add to route history when navigating forward
      if (this.routeHistory.slice().pop() !== e.urlAfterRedirects) {
        this.routeHistory.push(e.urlAfterRedirects)
      }
    })
  }
}

export const OTHER_OFFERING_ID = 'other'

export interface NavigationOptions {
  queryParams?: Record<string, string>
  routeParams?: Record<string, string>
}
